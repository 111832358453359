<script lang="ts">
  export let href: string;

  export let primary: boolean = false;
  export let newTab: boolean = false;
</script>

<a
  class={primary ? "primary-link" : "secondary-link"}
  {href}
  rel={newTab ? "noreferrer" : undefined}
  target={newTab ? "_blank" : undefined}
  on:click
>
  <slot />
</a>

<style lang="postcss">
  .primary-link {
    @apply text-primary font-medium;
  }

  .primary-link:hover {
    @apply underline;
  }

  .secondary-link {
    @apply underline underline-offset-2;
  }
</style>
